* {
  font-family: "Roboto", sans-serif !important;
}
body {
  margin: 0;
}

/* Hide the arrows for number inputs in WebKit browsers */
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Hide the arrows for number inputs in Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
/* WebKit browsers */
/* WebKit browsers */
::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 4px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: #888 #f1f1f1;
}
