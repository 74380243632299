.dashcaard {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #f4f3f0;
  }
  .dashrow {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 1rem;
  }
  
  .secondgraph {
    height: 400px; /* Adjust the height as per your requirement */
    border-radius: 10px;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
    margin-top: 3vh;
    padding: 8px;
  }
  
  .dashboardmaincontainer {
    background-color: #fefefe;
    margin-top: 1rem;
    height: 100%;
    padding: 2rem;
    border-radius: 16px;
  }
  
  .cardslice {
    /* padding: 1rem; */
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  }
  
  .firstgraph {
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
    min-width:350px;
    padding: 10px;
    border-radius: 10px;
    /* margin-top: 6%; */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .css-1qdzy9k-MuiBarElement-root {
    fill: #642315 !important;
    /* width: 20px !important; */
  }
  #dropdown-basic {
    background-color: unset;
    border: none;
  }
  h5 {
    font-family: "Roboto";
    color: #642315;
  }
  h6 {
    color: #525252;
  }
  p {
    font: bold;
    font-size: larger;
  }
  .dashboardsecondrow {
    margin-top: 35px;
  }
  .alldatainfo.col {
    margin-top: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #552255;
  }
  
  .hightalluser {
    line-height: 3rem;
    font-weight: 500;
  }
  .buttomline {
    width: 100%;
    height: 2px;
    color: #552255;
  }
  .alluserdataborder {
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
    padding: 1rem;
    border-radius: 10px;
    min-width: 250px;
    margin-top: 20px;
  }
  
  .alluserdatabordertop {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #f4f3f0;
    padding: 0.3rem;
    border-radius: 4px;
    margin-bottom: 1rem;
    place-items: center;
  }
  .alluserdatabordertop p {
    color: #1c1c1c;
    font-family: "Roboto";
    font-size: large;
    cursor: pointer;
    display: flex;
    align-items: center;
    padding: 2px;
    margin: 0 !important;
  }
  .graphgrid {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #f4f3f0;
    padding: 0.2rem;
    /* width: 88vh; */
    border-radius: 5px;
    margin-bottom: 2rem;
  }
  .graphsecondgrid {
    display: flex;
    gap: 1rem;
    align-items: center;
  }
  .graphTitle {
    background-color: #f4f3f0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 3px 5px 3px 5px;
    border-radius: 10px;
    gap: 70px;
  }
  .graphTitle div {
    background-color: #f4f3f0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 15px;
  }
  .graphTitle > div > p {
    margin: 0 !important;
    
  }
  .cardCol{
    width: 30%;
    min-width: 250px;
    padding: 0;
  }
  
  .dashboardchart{
    display: flex;
    justify-content: space-between;
  }
  .pichartfilter{
    
    border: none;
  border-radius: 3px;
  background-color: #F4F3F0;
  margin-top: 55%;
  outline: none;
  
  }
  .pichartfiltersmall{
    display: none;
  }
  @media (max-width: 500px) {
    .pichartfiltersmall{
      display: block;
    }
    .pichartfilter{
      display: none;
      margin-top: 0%;
    }
  }
  @media (max-width: 1024px) {
    .alluserdataborder{
      display: none;
    }
  }
  