/* #view-col {
  display: flex;
  justify-content: flex-end;
} */
#container-row {
  padding-left: 2rem;
  padding-right: 2rem;
  margin-top: 5px;
}
#heading-col {
  color: #642315;
  font-family: "Roboto";
  font-size: 20px;
  font-weight: 500;
  line-height: 20px;
  padding-bottom: 7px;
}
.topdataofview {
  color: #642315;
  font-family: "Roboto";
  font-size: 16px;
  font-weight: 500;
}
#hr-tag {
  color: #642315;
  border-bottom: 1.5px solid #642315;
}
/* color: "#642315E5", background: "#f4f3f0" */
#viewSaleryHeader {
  background: #f4f3f0;
  color: "#642315E5";
  padding: 10px;
  border-radius: 10px;
  font-family: "Roboto";
  margin: 1.5rem;
  padding: 0.5rem;
}

#crossicon {
  color: black;
  font-weight: 500;
}
#subadmincrossicon {
  display: flex;
  justify-content: end;
}
#heading-row {
  margin-top: 2vh;
}
#Table-Row {
  padding-left: 2rem;
  padding-right: 2rem;
}
.button {
  background-color: #e4cdaa;
  border: 1px solid #595959;
  color: #642315;
}
#d-col {
  display: flex;
  justify-content: end;
}
#button-row {
  padding-right: 3rem;
  margin-bottom: 2rem;
}

.ViewPayrollContainer {
  background-color: #fefefe;
  min-height: 90vh;
  margin-top: 1rem;
  padding-top: 0.2rem;
  padding: 0.4rem;
}
.css-lapokc {
  color: black !important;
}
