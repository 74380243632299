#deleteIcon {
    color: #642315;
    background-color: #e4cdaa;
    cursor: pointer;
  }
  
  .value {
    text-align: end;
  }
  
  .transactionHeading {
    color: #642315e5;
    font-weight: 500;
    border-bottom: 1.5px solid #642315e5;
    padding: 0.5rem;
  }
  
  #addNewIcon {
    margin-right: 1rem;
    width: 10rem;
    border: none;
    color: #642315;
    background-color: #e4cdaa;
    border-radius: 0.3rem;
  }
  
  /* #addNewTask{
      border-bottom:1px solid #642315;
      padding-bottom: 1rem;
  } */
  .radio-container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    padding: 20px;
  }
  
  .custom-placeholder::placeholder {
    color: #642315e5;
  }
  .custom-placeholder {
    background-color: #e4cdaa;
    color: #642315e5;
    border: 1px solid #642315e5;
  }
  .firstmodalcol{
    display: flex;
    justify-content: end;
  }