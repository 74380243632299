
#transaction-primary-container {
    background-color: white;
    min-height: 100vh;
    padding: 2rem 2rem 0rem 2rem;
    margin-top: 1rem;
    max-width: 110%;
  }
  
  .transaction-list {
    font-weight: 600;
    font-size: 1.15rem;
    color: #642315;
  }
  /* .btn-group {
     display: flex;
    flex-direction: row; 
    /* justify-content: end;
    align-items: center; 
  } */
  h1 {
    color: #642315;
    font-family: "Roboto", sans-serif;
    font-weight: 800;
    font-style: normal;
  }
  
  .second-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 25px;
    width: 100%;
  }
  
  #btn {
    color: #642315;
    border: #e4cdaa;
    background-color: #e4cdaa;
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    font-style: normal;
    margin-left: 1rem;
  }
  
  .view-button {
    border: #e4cdaa;
    color: #642315;
    background-color: #e4cdaa;
    padding: 2px 4px 2px 4px;
    border-radius: 5px;
    font-weight: 600;
    font-style: normal;
    width: 3rem;
  }
  .view-button:hover {
    border: #e4cdaa;
    color: #642315;
    background-color: #e4cdaa;
    padding: 2px 4px 2px 4px;
    border-radius: 5px;
    font-weight: 600;
    font-style: normal;
    width: 3rem;
  }
  .css-lapokc {
    color: #642315;
  }
  
  .icon {
    width: 1.5rem;
    height: 1.5rem;
    color: #642315;
  }
  
  #btn1 {
    color: #642315;
    border: #e4cdaa;
    background-color: #e4cdaa;
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    font-style: normal;
    margin-left: 3.5rem;
  }
  #plusicon{
    margin-top: -1vh;
  }
  .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation2.css-1atu56z-MuiPaper-root {
    border: none;
    box-shadow: none;
  }
  .view-button1 {
    border: none;
    color: black;
    background-color: #FA5F71;
    padding: 2px 4px 2px 4px;
    border-radius: 5px;
    font-weight: 600;
    font-style: normal;
    width: 4rem;
  }
  .view-button1:hover{
    border: none;
    color: black;
    background-color: #FA5F71;
    padding: 2px 4px 2px 4px;
    border-radius: 5px;
    font-weight: 600;
    font-style: normal;
    width: 4rem;
  }
  .Loader{
    height: 70vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  #filterBox{
   background-color: #f4f3f0 !important
  }