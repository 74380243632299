table {
  width: 100%;
  /* border: 1px solid #595959; */
  background-color: #f4f3f0;
  border-radius: 5px;
  border-collapse: separate;
  font-family: "Roboto";
  font-weight: 400;
}
.empmaincontainer {
  height: 100vh;
}

.second-box {
  display: flex;
  align-items: center;
  justify-content: end;
  margin-bottom: 1rem;
}

.tablepaddinginner {
  padding: 0.7rem;
}

.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation2.css-1atu56z-MuiPaper-root {
  border-radius: 10px;
}

#btnnn {
  color: #642315;
  border: #e4cdaa;
  background-color: #e4cdaa;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-style: normal;
  margin-left: 1rem;
  padding: 0.3rem;
  border-radius: 4px;
  float: right;
  padding: 10px 12px;
  margin-right: 80px;
}

.form-select {
  padding: 0rem 2.25rem 0rem 0.75rem;
  font-family: "Roboto";
  font-weight: 500;
  color: #642315;
  background-color: #e4cdaa;
  border: #e4cdaa;
  width: fit-content;
}
@media only screen and (max-width: 600px) {
  .selectButton {
    margin-top: 1rem;
  }
  .addNewSubButton {
    /* margin-right: 1rem; */
    width: 12.3rem;
  }
}
.selectButton,
.multiselect-dropdown {
  outline: none;
  box-shadow: none;
}

.selectButton:focus,
.multiselect-dropdown:focus {
  outline: none;
  box-shadow: none;
}
.manageRequiredMaterial {
  color: rgb(100, 35, 21);
}
.Loader {
  height: 70vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.filterbutton {
  width: 100%;
  display: flex;
  justify-content: end;
}
.filterbutton > div {
  width: 160px !important;
}
