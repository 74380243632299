.Container {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  gap: 50px;
}
.BUTTON {
  border: none;
  background-color: #dc2329;
  padding: 3px 10px 3px 10px;
  border-radius: 5px;
  cursor: pointer;
  width: 90px;
  margin-bottom: 20px;
  color: #fff;
}
/* .modal-content {
  padding: 1rem;
  width: 75%;
}
#transactionModal {
  display: flex;
  justify-content: center;
} */
