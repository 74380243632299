.topexpense {
    color: #642315;
    font-weight: 500;
    font-size: medium;
}

.salestopsecondexpense {
    display: flex;
    justify-content: end;
    gap:8px;
}
.sales-head{
    margin-bottom: 1rem;
}
.expenseDataContainerSales {
    min-height: 80vh; 
    background-color: #FEFEFE;
    display: flex;
    flex-direction: column;
    /* padding: 20px;  */
    box-sizing: border-box;
}
.Loader{
    height: 70vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }
