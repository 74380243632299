#ADNsubadminRow {
  margin-top: 2vh;
  margin-bottom: 2vh;
}
.ADNsubadminForm {
  color: #1c1c1c;
  font-size: 17px;
  font-weight: 600;
  font-weight: 500;
  margin-top: 10px;
}
#subadminFormcontrol {
  background-color: #f4f3f0;
  border: 1px solid #595959;
  padding-left: 10px;
  box-shadow: none;
}

#ADNsubadmin {
  font-family: Roboto;
  font-size: 18px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
  color: #642315e5;
}
#subadmincrossicon {
  display: flex;
  justify-content: flex-end;
  margin-top: 0.5vh;
}
#subadminmodalbody {
  padding: 8px;
  padding-left: 15px;
  padding-right: 15px;
}
#subadminmodalbody1 {
  margin-top: 2vh;
  margin-bottom: 2vh;
}
#selectoptionrow {
  margin-top: 2vh;
  margin-bottom: 2vh;
}
#selectoptionlebel {
  color: #1c1c1c;
  font-size: 15px;
  font-weight: 600;
}
#optionlebel {
  color: #595959bf !important;
  font-family: roboto;
  font-size: 17px !important;
}
#discardbutton {
  display: flex;
  justify-content: flex-end;
  margin-left: -1vw;
}
#submitbutton {
  margin-left: -1vw;
}
#subadminbuttton {
  background: #e4cdaa;
  border: 1px solid #595959;
  color: #642315e5;
  font-size: 12px;
}
.optionclass {
  font-family: Roboto;
  font-size: 15px;
  font-weight: 500;
  line-height: 20px;
  color: #444444;
}
#Eyeicon {
  position: absolute;
  right: 3vw;
  margin-top: -4.3vh;
}
#crossicon {
  font-size: 25px;
  color: #642315;
  font-weight: 700;
}
.saleformcontainer {
  background-color: #fefefe;
  padding: 0.7rem;
  margin-top: 1rem;
}
.saleTop {
  background-color: #f4f3f0;
  padding: 0.5rem;
  color: black;
  margin: 0.5rem;
  border-radius: 8px;
  align-items: center;
  display: flex;
  justify-content: space-between;
}
.crossform {
  width: 50px;
  height: 30px;
  align-items: end;
}
.sText {
  width: 80%;
  color: black;
  font-family: "Roboto";
  font-weight: 500;
}
.addformcol {
  display: flex;
  justify-content: end;
  margin-top: 2rem;
}

.form-select {
  font-family: "Roboto" !important;
  font-weight: 500;
  color: #642315 !important;
  background-color: #f4f3f0 !important;
  border: 1px solid #595959 !important;
  padding: 0.4rem !important;
  border: 1px solid !important;
  width: 100% !important;
}
.sallastend {
  display: flex;
  justify-content: end;
  margin-top: 1rem;
}

.horizonatlline {
  margin-top: -4px;
  margin-bottom: 12px;
}
