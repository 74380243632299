#view-col {
  display: flex;
  justify-content: end;
  /* width: 100%; */
  margin-top: 5px;
}
#view-col-num {
  display: flex;
  justify-content: end;
  font-weight: 600;
  margin-top: 5px;
  padding: 0;
}
#bolder-text {
  font-weight: 600;
}
#container-row {
  padding-left: 2rem;
  padding-right: 2rem;
}
#heading-col {
  color: #642315;
  font-family: Roboto;
  font-size: 20px;
  font-weight: 500;
  line-height: 20px;
}
#hr-tag {
  color: #642315;
  border-bottom: 1.5px solid #642315;
}
#hr-tag-small {
  color: #642315;
  border-bottom: 1px solid black;
  margin-top: 5px;
}
#subadminmodalheader {
  background: #e4cdaa;
  padding: 10px;
  padding-left: 22px;
  padding-right: 19px;
  border-radius: 10px;
  margin-top: 3vh;
  margin-left: 0.1vw;
  display: flex;
  justify-content: center;
  align-items: center;
}
#ADNsubadmin {
  font-family: Roboto;
  font-size: 18px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
  color: #642315e5;
}

#crossicon {
  font-size: 25px;
  color: #642315;
  font-weight: 700;
}
#subadmincrossicon {
  display: flex;
  justify-content: flex-end;
  margin-top: 0.5vh;
}
#heading-row {
  margin-top: 2vh;
}
#Table-Row {
  padding-left: 2rem;
  padding-right: 2rem;
}
.button {
  background-color: #e4cdaa;
  border: 1px solid #595959;
  color: #642315;
}
#d-col {
  display: flex;
  justify-content: flex-end;
}

#SalesContainer {
  background-color: #fefefe;
  min-height: 90vh;
  margin-top: 1rem;
  padding-top: 0.2rem;
}
.sale-view-top {
  background-color: #e4cdaa;
  margin: 1.5rem 1rem;
  padding: 0.5rem;
  border-radius: 10px;
}
.info-container {
  padding: 0 1rem;
}
.order-hist-modal {
  padding: 1.5rem;
  background-color: white;
}
.leftContent {
  color: #642315;
  font-family: "Roboto";
  font-size: medium;
  font-weight: 500;
  width: 222px;
  padding: 0;
}
.rightContent {
  display: flex;
  justify-content: end;
  align-items: center;
  cursor: pointer;
  width: 20px;
  padding: 0;
}
.invoiceForm {
  display: flex;
  justify-content: end;
  margin-top: 10px;
  margin-right: 10px;
  margin-bottom: 1rem;
  padding-bottom: 2rem;
}
