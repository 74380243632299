#transaction-detailed-row {
  background: #e4cdaa;
  /* padding: 10px; */
  /* padding-left: 22px; */
  padding-right: 19px;
  border-radius: 10px;
  margin-top: 2vh;
  width: 99.8%;
  /* margin-left: 0.1vw; */
  margin-bottom: 1rem;
}
#transaction-information {
  font-family: Roboto;
  font-size: 18px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
  color: #642315e5;
}
#transaction-crossicon {
  font-size: 25px;
  color: #642315;
  font-weight: 700;
}
#Transaction-details {
  font-family: Roboto;
  font-size: 21px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
  color: #642315;
}
#text-row {
  margin-top: 1.5vh;
}
#hrtag {
  color: #642315;
  border-bottom: 1.5px solid #642315;
}
#text-row1 {
  margin-top: 1.6vh;
  padding-left: 10px;
  padding-right: 10px;
}
.text-class {
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  color: #1c1c1c;
}
#text-row2 {
  margin-top: 3vh;
}
#value-column {
  display: flex;
  justify-content: flex-end;
}
#download-transaction {
  background-color: #f4f3f0;
  border: 0.5px solid #595959;
  color: #1c1c1c;
  font-family: Roboto;
  font-size: 17px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
}
#download-icon {
  color: #642315;
  font-weight: 800;
  margin-top: -0.8vh;
}
.button {
  background-color: #e4cdaa;
  border: 1px solid #595959;
  color: #642315;
}
#delete-button {
  display: flex;
  justify-content: flex-end;
  /* margin-right: -2vw; */
}
#edit-button {
  display: flex;
  justify-content: flex-end;
}
#button-row {
  margin-top: 1vh;
}
#transaction-container {
  background-color: white;
  padding: 20px;
  margin-top: 1rem;
}
