#ADNsubadminRow{
    margin-top: 2vh;
    margin-bottom: 2vh;
}
#ADNsubadminForm{
    color: #1C1C1C;
    font-size: 17px;
    font-weight: 600;
    font-weight: 500;
}
#subadminFormcontrol{
    background-color: #F4F3F0;
    border: 1px solid #595959;
    padding-left: 10px;
   box-shadow: none;
}
#subadminmodalheader{
    background: #E4CDAA;
    padding: 10px;
    padding-left: 22px;
    padding-right: 19px;
    border-radius: 10px;
    margin-top: 3vh;
    /* width: 99.8%; */
    margin-left: 0.1vw;
    display: flex;
    justify-content: center;
    align-items: center;
}
#ADNsubadmin{
    font-family: Roboto;
    font-size: 18px;
    font-weight: 500;
    line-height: 20px;
    text-align: left;
    color: #642315E5;
}
#subadmincrossicon{
    display: flex;
    justify-content: flex-end;
    margin-top: 0.5vh;
}
#subadminmodalbody{
    padding: 8px;
    padding-left: 15px;
    padding-right: 15px; 
}
#subadminmodalbody1{
    margin-top: 2vh;
    margin-bottom: 2vh; 
}
#selectoptionrow{
    margin-top: 2vh;
    margin-bottom: 2vh;
}
#selectoptionlebel{
    color: #1C1C1C;
    font-size: 15px;
    font-weight: 600;
}
#optionlebel{
    color: #595959BF !important;
    font-family: roboto;
   font-size: 17px !important;
}
#discardbutton{
    display: flex;
    justify-content: flex-end;
    margin-left: -1vw;
}
#submitbutton{
    margin-left: -1vw;
}
#subadminbuttton{
    background: #E4CDAA;
    border: 1px solid #595959;
    color: #642315E5;
    font-size: 12px;
}
.optionclass{
    font-family: Roboto;
font-size: 15px;
font-weight: 500;
line-height: 20px;
color: #444444;
}
#Eyeicon{
position: absolute;
right: 3vw;
margin-top: -4.3vh;

}
#crossicon {
    font-size: 25px;
    color: #642315;
    font-weight: 700;
  }
@media screen and (min-width: 344px) and (max-width :768px){
    #Eyeicon {
        right: 11vw;
       
    }
 }
 @media screen and (min-width: 768px) and (max-width:1000px){
    #Eyeicon {
        right: 5vw;
        margin-top: -3vh;
       
    }
 }
 @media screen and (min-width: 820px) and (max-width :1000px){
    #Eyeicon {
        right: 5vw;
        margin-top: -2.3vh;
       
    }
 }
  #modal{
 justify-content: center;
 align-items: center;
 } 
 #modal1{
    max-width: 70%;
 }
 /* @media screen and (min-width: 344px) and (max-width :768px){
    #modal1 {
       max-width: 100%;
       
    }
 } */