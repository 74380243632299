#addNew {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
  /* margin-right: 0.5rem; */
  border: none;
  color: #642315;
  background-color: #e4cdaa;
  border-radius: 0.4rem;
  padding: 0 1rem;
  font-weight: 500;
  min-width: 130px;
  padding: 10px 5px 10px 10px;
}

#addNew:hover {
  background-color: #c9974b;
  color: white;
  transition: all 0.3s;
}

#filter {
  margin-right: 1rem;
  /* width: 7rem; */
  padding: 0 1rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
  border: none;
  color: #642315;
  background-color: #e4cdaa;
  border-radius: 0.4rem;
  font-weight: 500;
}

#filter:hover {
  background-color: #c9974b;
  color: white;
  transition: all 0.3s;
}
.statusBtn {
  background-color: #fa5f71;
  border: none;
  color: white;
  padding-left: 0.7rem;
  padding-top: 0.3rem;
  padding-bottom: 0.3rem;
  border-radius: 0.2rem;
}

.statusBtnComplete {
  background-color: #0fa958;
  border: none;
  color: white;
  padding: 0.3rem;
  border-radius: 0.2rem;
}
.pleaseChooseAnEmp {
  background-color: #e4cdaac6;
  border-radius: 7px;
  height: 30px;
  padding-left: 3px;
  padding-right: 5px;
  transition: all 0.5s;
  cursor: pointer;
  border: none;
}
.material-status-btn {
  border: none;
  padding: 0.3rem;
  border-radius: 0.2rem;
  font-family: "Roboto";
  font-weight: 500;
  color: white;
  width: 6rem;
}

.subadmin-btn {
  width: 100%;
  display: flex;
  justify-content: end;
}

.responsive-cell {
  width: 100%;
}

.color-circle,
.pattern-circle {
  width: 3vw;
  height: 6vh;
  border-radius: 50%;
  min-width: 30px;
  min-height: 30px;
}
.color-circle {
  width: 2.9vw;
  height: 6vh;
  border-radius: 50%;
  min-width: 30px;
  min-height: 30px;
}

@media (max-width: 768px) {
  .color-circle,
  .pattern-circle {
    width: 6vw;
    height: 6vw;
  }
}
#filterButton {
  width: 50% !important;
  padding: 8px 2px !important;
  /* margin-right: 2vw; */
}
.Loader {
  height: 70vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.firstRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 1rem;
  padding: 0;
}
.leftbutton {
  /* width: 100px;
  display: flex;
  justify-content: start; */
}
.leftbutton > div {
  padding: 0;
  margin-right: 10px;
  width: 130px;
}
.rightButton {
  height: 99% !important;
  display: flex;
  justify-content: end;
  gap: 1rem;
  margin-left: 0.1rem;
}
