.loginContainer {
  font-family: "Roboto", sans-serif;
  padding-top: 1rem;
  background-color: #c9c7c5;
  height: 100vh;
  width: 100vw;
}
#loginBelowText {
  font-size: 16px;
}

#loginDiv {
  background-color: #ffffff;
  width: 50%;
  border-radius: 0.9rem;
  padding-left: 3rem;
  padding-right: 3rem;
  padding-bottom: 2rem;
  padding-top: 1rem;
}

#mmIcon {
  height: auto;
  width: 150px;
}

.input {
  background-color: #ffffff;
  border: 1px solid #595959;
  padding: 7px;
}

.input::placeholder {
  color: black;
}

.label {
  font-weight: 500;
}

#loginHeading {
  font-size: 36px;
  font-weight: 600;
  color: #642315;
}
.forgot-password {
  cursor: pointer;
  position: absolute;
  bottom: -23px;
  right: 0;
  font-size: 14px;
  color: #642315;
}

#loginBtn {
  background-color: #dc2329;
  /* border: 1px solid #642315; */
  border: none;
  margin-top: 1rem;
  color: #fff;
  width: 100%;
  margin-bottom: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

#eyeOpenIcon {
  position: absolute;
  right: 23px;
  top: 43px;
}

#eyeCloseIcon {
  position: absolute;
  right: 23px;
  top: 43px;
}
#homesignup {
  font-weight: 400;
}
#homesignup span {
  color: #642315;
  font-weight: 500;
  cursor: pointer;
}
@media (max-width: 767px) {
  #eyeOpenIcon {
    position: absolute;
    right: 10px;
    top: 45px;
  }

  #eyeCloseIcon {
    position: absolute;
    right: 10px;
    top: 43px;
  }
  #loginDiv {
    border-radius: 10px;
    padding: 10px;
    width: 100%;
    margin-top: -3rem;
  }

  #loginHeading {
    font-size: 2rem;
    font-weight: 600;
    color: #642315;
  }
}

.Adminmessage {
  text-align: center;
  font-weight: 500;
  font-size: medium;
  font-family: "Roboto";
  margin: 0;
}
