.titleofcontainerrrr {
  margin-top: 20px;
}

.search-wrapper.searchWrapper {
  border-radius: 6px;
  background-color: #f4f3f0;
  border: 1px solid #595959;
}

.productlastcol {
  border: dotted 1px solid;
}

.productlastcol {
  width: 150px;
  height: 150px;
  border: 1px dashed #ccc;
  background-color: #f6f6f6;
  border-radius: 5px;
  margin: 10px 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.productlastcol:hover {
  background-color: #e0e0e0;
}

#transaction-crossicon {
  cursor: pointer;
}

@media only screen and (max-width: 600px) {
  .productlastcol {
    /* width: 80%; */
    margin-left: 0;
    margin-top: 1rem;
  }
}

.productlastcol img {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

.productlastcol:hover:before {
  color: #333;
}
.subadminlastbutton {
  margin-top: 1rem;
}
.prodoductfoamrow {
  margin-top: 0.7rem;
}

.amthirdcoltype {
  padding: 0.5rem;
  color: black;
  font-weight: normal;
  font-family: "Roboto";
  background-color: #f4f3f0;
  border: 1px solid #595959;
}
.amthirdcollll {
  padding: 0.5rem;
  color: black;
  font-weight: normal;
  font-family: "Roboto";
  background-color: #f4f3f0;
  border: 1px solid #595959;
}
.pattern {
  display: flex;
  flex-direction: row;
  width: 600px;
  height: 50px;
  transition: 0.4s all;
}
.add-button {
  cursor: pointer;
  color: #642315;
  font-size: 25px;
}
.color-container {
  display: flex;
  flex-direction: row;
}
.add-btn {
  transform: scale(0.8);
  margin-left: 0.5rem;
}
.color-box {
  width: 35px;
  height: 35px;
  border-radius: 50%;
}
.color-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 8px;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
.color-box-container {
  border: 2px solid black;
  border-radius: 50%;
  padding: 2px;
  position: relative;
}
.cross-mark {
  position: absolute;
  top: -8px;
  right: -5px;
  font-size: 12px;
  cursor: pointer;
}
.loader {
  margin-top: 10px;
  margin-right: 5px;
  height: 25px;
  width: 25px;
}
.uploadLoader {
  margin: 35px;
  height: 40px;
  width: 40px;
}
.imageInPattern {
  height: 50px;
  width: 50px;
 display: flex;
 justify-content: center;
 align-items: center;
}
.cross-mark-pattern {
  position: absolute;
  right: 2px;
  top: 2px;
  font-size: 15px;
  cursor: pointer;
}
.cross-mark-picture {
  position: absolute;
  top: -45px;
  right: -25px;
  font-size: 20px;
  cursor: pointer;
}

.colorandbuttoncontainer{
  display: flex;
  gap: 1vw;
  align-items: center;
}
.patternofproductfprm{
  display: flex;
  align-items: center;
  gap: 1vw;
}

.subadminform{
  background-color: #FEFEFE;
}
#amtopbarsalery {
  background-color: #e4cdaa;
  font-family: "Roboto";
  padding: 0.5rem;
  font-size: large;
  color: #642315;
  margin-top: 3vh;
  border-radius: 9px;
  margin-top: 1vh;
}
.firstnames{
    font-family: "Roboto";
    font-weight: 500;
  }

  #amtopbarsalery{
    background-color: #E4CDAA;
    font-family: "Roboto";
    padding: 0.5rem;
    font-size: large;
    color: #642315;
    margin-top: 3vh;
    font-weight: 500;
    border-radius: 9px;
    margin-top: 1vh;
  }
  
  .amsecondcol{
      text-align: end;
      font-family: "Roboto";
  }
  .secondrow{
      background-color: #F4F3F0;
      font-family: "Roboto";
      padding: 0.5rem;
      font-size: large;
      color: #642315;
      margin-top: 3vh;
      border-radius: 9px;
      border: 1px solid #595959;
  
  }
  
  .amthirdcol{
      background-color:  #F4F3F0;
      margin-top: 0.2rem;
      margin-bottom: 0.4rem;
      border: 1px solid #595959;
  }
  
  .amproductdetails {
      font-family: "Roboto";
      color: #642315;
      font-weight: 500;
      font-size: larger;
      margin-top: 2vh;
      margin-bottom: 0.4rem;
  }
  
  
    .amlastrow{
      
      margin-top: 2vh;
      margin-bottom: 2rem;
  }
  
  .glo-button-group {
      display: flex;
      justify-content: end;
      gap: 10px; 
    }
    .subadminform {
      background-color: #FEFEFE;
      padding: 1.5rem;
      border-radius: 14px;
      margin-top: 2rem;
  }
  
    .titleofcontainer{
      font-family: "Roboto";
      font-weight: 500;
    }
  
    .subadminlastbutton{
      /* margin-top: 1rem; */
      display: flex;
      justify-content: end;
      gap: 1rem;
      margin-right: 0.1rem;
      /* margin-bottom: 5rem; */
    }
    .selecttagsubadminform{
      background-color:  #F4F3F0;
      margin-top: 0.2rem;
      margin-bottom: 0.4rem;
      width: 100%;
          border: 1px solid #595959;
          padding: 0.4rem;
          border-radius: 5px;
      
    }
    .firstnames{
      font-family: "Roboto";
      font-weight: 500;
    }
    .admincrossicon{
      display: flex;
      margin-top: 0.3rem;
      justify-content: right;
    }
  
  
    .lastrowemp {
      margin: 0;
  }




  
  
