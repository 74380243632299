/* .Expenses{
  background-color: #E4CDAA;
  color: #642315;
  font-family: "Roboto";

} */

.nav-tabs .nav-link {
  background-color: white;
  color: #642315;
  font-family: "Roboto";
  font-weight: 500;
}

.nav-tabs .nav-link.active {
  background-color: #dbd7c9;
  color: black;
  font-family: "Roboto";
  font-weight: 500;
}
.fullcontainer {
  background-color: #fefefe;
  padding: 0.7rem;
  margin-top: 1rem;
}
