#view-col {
  text-align: end;
  width: 62%;
  padding: 0;
}
#container-row {
  padding-left: 2rem;
  padding-right: 2rem;
}
#heading-col {
  color: #642315;
  font-family: Roboto;
  font-size: 20px;
  font-weight: 500;
  line-height: 20px;
}
#hr-tag {
  color: #642315;
  border-bottom: 1.5px solid #642315;
}
#subadminmodalheader {
  background: #e4cdaa;
  padding: 10px;
  padding-left: 22px;
  padding-right: 19px;
  border-radius: 10px;
  margin-top: 3vh;
  margin-left: 0.1vw;
  display: flex;
  justify-content: center;
  align-items: center;
}
#ADNsubadmin {
  font-family: Roboto;
  font-size: 18px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
  color: #642315e5;
}

#crossicon {
  font-size: 25px;
  color: #642315;
  font-weight: 700;
}
#subadmincrossicon {
  display: flex;
  justify-content: flex-end;
  margin-top: 0.5vh;
}
#heading-row {
  margin-top: 2vh;
}
#Table-Row {
  padding-left: 2rem;
  padding-right: 2rem;
}
.button {
  background-color: #e4cdaa;
  border: 1px solid #595959;
  color: #642315;
}
#d-col {
  display: flex;
  justify-content: flex-end;
}

#SalesContainer {
  background-color: #fefefe;
  min-height: 90vh;
  margin-top: 1rem;
  padding-top: 0.2rem;
}
.saleviewtop {
  background-color: #f4f3f0;
  margin: 1.5rem;
  color: black;
  padding: 0.5rem;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.leftconatint {
  color: #642315;
  font-family: "Roboto";
  font-size: medium;
  font-weight: 500;
  width: 215px;
  padding: 0;
}
.rightconatint {
  width: 20px;
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 0;
  margin: 0;
}
.invoiceform {
  display: flex;
  justify-content: end;
  margin-top: 10px;
  margin-right: 10px;
  /* margin-bottom: 5rem; */
}
.rowData {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 5%;
}
.key {
  width: 32% !important;
  padding: 0;
}
