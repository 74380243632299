#transaction {
  background: #e4cdaa;
  padding: 10px;
  padding-left: 22px;
  padding-right: 19px;
  border-radius: 10px;
  margin-top: 3vh;
  width: 99.8%;
  margin-left: 0.1vw;
  display: flex;
  justify-content: center;
  align-items: center;
}
#transaction1 {
  font-family: Roboto;
  font-size: 18px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
  color: #642315e5;
}
#transaction2 {
  display: flex;
  justify-content: flex-end;
  margin-top: 0.5vh;
}
#transaction3 {
  padding: 8px;
  padding-left: 15px;
  padding-right: 15px;
}
#transaction4 {
  margin-top: 2vh;
  margin-bottom: 2vh;
}
#transaction5 {
  color: #1c1c1c;
  font-size: 15px;
  font-weight: 500;
}
#transaction6 {
  margin-top: 2vh;
  margin-bottom: 2vh;
}
#formid1 {
  background-color: #f4f3f0;
  border: 1px solid #595959;
  padding: 7px;

  border-radius: 8px;
}
#formid2 {
  display: flex;
  flex-direction: row;
}
#lebelid {
  font-size: 15px;
  font-size: x-small;
  margin-left: 2vw;
}
#transaction10 {
  background: #e4cdaa;
  display: flex;
  justify-content: center;
  border: 1px solid #595959;
  color: #642315e5;
  font-size: 12px;
  width: 75px;
}
#transaction7 {
  display: flex;
  justify-content: flex-end;
}

#transaction9 {
  margin-left: -1.5vw;
}

#formid4 {
  padding: 8px;
  padding-left: 12px;
  padding-right: 30px;
}
input[type="radio"] {
  border-color: #642315;
}
input[type="radio"]:checked {
  background: #642315;
  box-shadow: none;
}
#formid123 {
  background-color: #f4f3f0;
  border: 1px solid #595959;
  padding-left: 10px;
  box-shadow: none;
}
input[type="date"]::-webkit-calendar-picker-indicator {
  font-size: 20px;
  color: #642315;
}
#crossicon {
  font-size: 25px;
  color: #642315;
  font-weight: 700;
}
::placeholder {
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
  color: #595959 !important;
}
#form-lebel {
  font-size: 20px;
  font-weight: 700;
  color: #642315;
}
#container-id {
  background-color: white;
}
