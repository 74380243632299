
#productImgHead {
    color: #642315e5;
    margin-bottom: 1rem;
  }
  .ImageContainer {
    /* padding-left: 5.5rem; */
    padding-right: 1.5rem;
  }
  #deleteIcon {
    color: #642315;
    background-color: #e4cdaa;
    cursor: pointer;
  }
  .value {
    text-align: end;
  }
  .transactionHeading {
    color: #642315e5;
    font-weight: 500;
    border-bottom: 1.5px solid #642315e5;
    padding: 0.5rem;
  }
  .subadminlastbutton {
    margin-top: 1rem;
    display: flex;
    justify-content: end;
    gap: 1rem;
    margin-right: 0.1rem;
  }
  .leftButton {
    margin-top: 1rem;
    display: flex;
    justify-content: start;
    gap: 1rem;
    margin-left: 0.1rem;
  }
  .productImage {
    width: 1000px;
    height: 150px;
    display: flex;
    flex-direction: row;
    gap: 10px;
  }
  .viewProduct {
    height: 150px;
    border-radius: 10px;
    width: 120px;
  }
  #generateQR {
    border: none;
    color: #642315;
    background-color: #e4cdaa;
    padding: 5px;
    border: 1px solid #642315;
    border-radius: 0.3rem;
  }
  @media only screen and (max-width: 900px) {
    .productImage {
      height: 100px;
      width: 600px;
    }
    .ImageContainer {
      padding-left: 5px;
      padding-right: 5px;
    }
    .viewProduct {
      height: 100px;
      width: 70px;
    }
  }
  .ImageContainer {
    display: flex;
    flex-wrap: wrap;
  }
  .colorOptionBox {
    display: flex;
    justify-content: end;
    gap: 10px;
    margin-bottom: 8px;
  }
  .colorOption {
    border-radius: 50%;
    height: 30px;
    width: 30px;
    border: 1px solid black;
    object-fit: fill;
    cursor: pointer;
  }
  .textureBox {
    display: flex;
    justify-content: end;
    gap: 10px;
  }
  .texture {
    border-radius: 50%;
    height: 30px;
    width: 30px;
    border: 1px solid black;
    object-fit: fill;
    cursor: pointer;
  }
  .viewbuttonfeature.col {
    display: flex;
    justify-content: right;
  }
  .description-slider,
  .key-features-slider {
    max-height: 0;
    overflow-y: hidden;
    transition: max-height 0.6s ease-out;
  }
  .description-slider.open,
  .key-features-slider.open {
    max-height: 200px;
    overflow-y: auto;
    transition: max-height 0.6s ease-in;
  }
  .all-button{
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    margin:10px 0;
  }
  .twoButton{
    display: flex;
    flex-direction: row;
    gap: 20px;
  }
  .Button{
    border: none;
    border-radius: 10px;
    background-color: #e4cdaa ;
    padding: 5px 10px;
    color:#642315 ;
  }
  .Button:hover{
    background-color: #e4cdaa ;
  }
  .allNotification{
    display: flex;
    flex-direction: column;
    gap: 10px;
    overflow-y: auto;
    height: 400px;
  }
  .Notification{
    background-color:#FEFAE4 ;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 5px 10px;
    align-items: center;
    margin-top: 10px;
    margin: 0;
  }
  .notificationCrossIcon{
    display: flex;
    flex-direction: row;
    justify-content: end;
    align-items: flex-end;
  }
  .cross{
    cursor: pointer;
  }
  .Inventory{
    color: #642315;
  }
  .notificationNotFound{
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    color:#642315  ;
  }
  .notificationDateCross{
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    align-content: end;
  }
  .spinner{
    height: 400px;
    display: flex;
    justify-content: center;
    align-items: center;
  }