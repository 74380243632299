#vendor-button {
 min-width: 200px;
  display: flex;
  padding: 0;
  justify-content: flex-end;
}

.vendorbutton1 {
  background-color: #e4cdaa;
  color: #642315;
  border-radius: 7px;
  border: none;
  font-family: "Roboto";
  font-weight: 500;
  display: flex;
  justify-content: flex-end;
}
.vendorbutton1 > button {
  display: flex;
  justify-content: flex-end;
}
#vendorbuttonrow1 {
  margin-top: 1vh;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
}
#bils {
  font-family: "Roboto";
  font-size: 24px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.25px;
  color: #642315;
  display: flex;
  align-items: center;
  min-width: 200px !important;
  padding: 0;
  justify-content: flex-start;
}
.billselect {
  width: fit-content !important;
}
#transaction-primary-container {
  background-color: white;
  min-height: 100vh;
  padding: 2rem 2rem 0rem 2rem;
  margin-top: 2rem;
  font-family: "Roboto";
}

.viewbutton {
  border: #e4cdaa;
  color: #642315;
  background-color: #e4cdaa;
}
#recent-order-row {
  margin-top: 2rem;
}
#recent-order-col {
  font-family: Roboto;
  font-size: 20px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.25px;
  color: #1e1e1e;
}
.villrow {
  margin-top: 2vh;
}
.billreportleft {
  display: flex;
  justify-content: end;
  margin-top: 2rem;
}
.Loader {
  height: 70vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
