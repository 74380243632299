.dashcaard {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f4f3f0;
}
.dashrow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1rem;
}

.secondgraph {
  height: 400px; /* Adjust the height as per your requirement */
  border-radius: 10px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  margin-top: 3vh;
}

.dashboardmaincontainer {
  display: flex;
  flex-direction: column;
  gap: 10px;
  background-color: #fefefe;
  margin-top: 1rem;
  height: 100%;
  padding: 2rem;
  border-radius: 16px;
}

.cardslice {
  /* padding: 1rem; */
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
}

.firstgraph {
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  /* margin-top: 2rem; */
  padding: 0.4rem;
  border-radius: 10px;
}
.css-1qdzy9k-MuiBarElement-root {
  fill: #642315 !important;
  /* width: 20px !important; */
}
#dropdown-basic {
  background-color: unset;
  border: none;
}
h5 {
  font-family: "Roboto";
  color: #642315;
}
h6 {
  color: #525252;
}

.dashboardsecondrow {
  margin-top: 7vh;
}
.alldatainfo.col {
  margin-top: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #552255;
}

.hightalluser {
  line-height: 3rem;
  font-weight: 500;
}
.buttomline {
  width: 100%;
  height: 2px;
  color: #552255;
}
.alluserdataborder {
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  padding: 1rem;
  border-radius: 10px;
}
.alluserdatabordertop {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f4f3f0;
  padding: 0.3rem;
  border-radius: 4px;
  margin-bottom: 1rem;
}
.alluserdatabordertop .p2 {
  color: #1c1c1c;
  font-family: "Roboto";
  font-size: large;
}
.graphgrid {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f4f3f0;
  padding: 0.2rem;
  /* width: 88vh; */
  border-radius: 5px;
  margin-bottom: 2rem;
}
.graphsecondgrid {
  display: flex;
  gap: 1rem;
  align-items: center;
}

.dashboardaction {
  font-family: "Roboto";
  font-weight: 500;
  letter-spacing: 0.3px;
  font-size: large;
}
.lineaction {
  height: 1.5px;
  color: #e0e0e0;
  background-color: #e0e0e0;
  margin-top: 2vh;
}
.dashboadrrow {
  margin-top: 6vh;
}
.leftdashboardbutton {
  text-align: center;
  border-radius: 7px;
}
.rightdashboardbutton {
  text-align: center;
  border-radius: 7px;
}

@media (max-width: 600px) {
  .rightdashboardbutton {
    margin-top: 3vh;
  }
}

.dashboardthirdrow {
  margin-top: 6vh;
}
.lowerdashboeardcol {
  display: flex;
  margin-top: 2vh;
  justify-content: end;
}
.datespan {
  font: 500;
}
.lowerdashboeardcoltext {
  font-family: "Roboto";
}
.lowerdashbline {
  height: 0.8px;
  background-color: #888888;
}
.mainRow {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 20px;
}

@media (min-width: 600px) {
  .mainRow {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    gap: 20px;
  }
}

.firstGraph {
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  min-width: 310px;
  /* padding: 20px; */
  padding-top: 20px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.firstgraph{
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  min-width: 350px;
  padding-top: 20px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

@media (max-width: 400px) {
  .firstgraph {
   transform: scale(0.9);
   transition-duration: 3s;
  }
}

.graphtitle {
  background-color: #f4f3f0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 8px 10px 8px 10px;
  border-radius: 10px;
  gap: 70px;
  width: 98%;
  margin-bottom: 10px;
}
.graphtitle div {
  display: flex;
  justify-content: center;
  align-items: center;
}
.graphtitle div p {
  margin: 0 !important;
}
.Card{
  display: flex;
  flex-direction: column;
  gap: 20px;
}