#transaction-primary-container {
  background-color: white;
  min-height: 100vh;
  /* padding: 2rem 2rem 0rem 2rem; */
  /* margin-top: 1rem; */
}

.transaction-list {
  font-family: Roboto;
  font-size: 24px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.25px;
  color: #642315;
}
/* .btn-group {
     display: flex;
    flex-direction: row; 
    /* justify-content: end;
    align-items: center; 
  } */
h1 {
  color: #642315;
  font-family: "Roboto", sans-serif;
  font-weight: 800;
  font-style: normal;
}

.second-box {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0px !important;
  width: 100%;
}

#btn {
  color: #642315;
  border: #e4cdaa;
  background-color: #e4cdaa;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-style: normal;
  display: flex;
  align-items: center;
  gap: 5px;
}

.view-button {
  border: #e4cdaa;
  color: #642315;
  background-color: #e4cdaa;
  padding: 2px 4px 2px 4px;
  border-radius: 5px;
  font-weight: 600;
  font-style: normal;
  /* width: 3rem; */
}
.view-button:hover {
  border: #e4cdaa;
  color: #642315;
  background-color: #e4cdaa;
  padding: 2px 4px 2px 4px;
  border-radius: 5px;
  font-weight: 600;
  font-style: normal;
  width: 3rem;
}
.css-lapokc {
  color: #642315;
}

.icon {
  width: 1.5rem;
  height: 1.5rem;
  color: #642315;
}

#btn1 {
  color: #642315;
  border: #e4cdaa;
  background-color: #e4cdaa;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-style: normal;
  margin-left: 3.5rem;
}
#plusicon {
  margin-top: 0vh;
}
.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation2.css-1atu56z-MuiPaper-root {
  border: none;
  box-shadow: none;
}
.Loader {
  height: 70vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.payrollselect {
  margin-top: 15px;
  padding: 0;
}
@media (max-width: 800px) {
  .second-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 0px !important;
    width: 100%;
    margin-left: 0px;
  }
  .transaction-list {
    width: 100%;
    display: flex;
    align-items: start;
    font-size: 20px;
    min-width: 200px;
  }
  .payrollselect {
    width: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: end;
    min-width: 200px;
  }
}

.accountsSelectTag {
  background-color: #dc2329 !important;
  color: #fff !important;
}
